<template>
  <div class="basic-info">
    <div class="header">
      <div class="header_top">
        <img src="../../../assets/left.png" alt @click="goBack" />
        <span class="center">合同管理</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">合同模板</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">合同模板详情</span>
      </div>
    </div>
    <el-card>
      <div class="text item">
        <el-row>
          <el-col :span="12" class="text-align">编码：{{ detailInfo.code }}</el-col>

          <el-col :span="12" class="text-align" style="display: inline-block">名称：<span style="white-space: normal;word-wrap: break-word;">{{ detailInfo.name }}</span></el-col>

          <el-col :span="12" class="text-align">描述：{{ detailInfo.desc }}</el-col>

          <el-col :span="24" class="text-align">
            <!-- <iframe
              id="viewIframe"
              width="100%"
              height="600"
              :src="contentSrc"
              frameborder="0"
            ></iframe>-->
            <iframe
              id="myIframe"
              @load="load($event)"
              :src="contentSrc"
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
            <!-- <div v-html="detailInfo.content" class="w-e-text-container"></div> -->
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-dialog
      class="showImgDialog"
      width="680px"
      :center="true"
      :title="dialogTitle"
      :visible.sync="showImgVisible"
      :append-to-body="true"
    >
      <div class="imgBox">
        <img :src="dialogImgUrl" class="img-resource" alt />
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import config from "../../../config/config";
//接口：
import { api } from '/src/api/base';
import { generatePath } from '../../../api/base/index';
//新增接口
export const ContractTemplateAdd = api('/contractTemplate')(
  'contractTemplate.add.json'
);
//查询列表接口
export const ContractTemplateList = api('/contractTemplate')(
  'contractTemplate.list.json'
);
//查询详情接口
export const ContractTemplateDetail = api('/contractTemplate')(
  'contractTemplate.describe.json'
);
//编辑接口
export const ContractTemplateEdit = api('/contractTemplate')(
  'contractTemplate.edit.json'
);
//删除接口
export const ContractTemplateDel = api('/contractTemplate')(
  'contractTemplate.del.json'
);
export default {
  data () {
    return {
      code: '',
      detailInfo: {
        code: '',

        name: '',

        desc: '',

        content: ''
      },
      dialogTitle: '',
      showImgVisible: false,
      dialogImgUrl: '',
      contentSrc: '',

    };
  },

  created () {
    this.code = this.$route.query.code;

    ContractTemplateDetail({ code: this.code }).then(res => {
      this.detailInfo = res;
      this.contentSrc =
        generatePath() + 'contractTemplate/' + res.code + '/document.html';
    });
  },
  methods: {
    load (event) {
      console.log(event);
      window.iFrameResize({ log: true, checkOrigin: false }, '#myIframe');
    },
    goBack () {
      if (this.$route.query.name) {
        this.$router.push({
          path: '/system/contract/contractTemplate',
          query: {
            code: this.code
          }
        });
      }
      else {
        window.history.go(-1);
      }

    },
    showImg (img, title) {
      this.dialogImgUrl = img;
      console.log(this.dialogImgUrl);
      this.dialogTitle = title;
      this.showImgVisible = true;
    },
    dialogClose () {
      this.dialogImgUrl = '';
      this.dialogTitle = '';
      this.showImgVisible = false;
    }
  }
};
</script>

<style lang="scss">
.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}
.basic-info .font-img {
  font-size: 26px;
  cursor: pointer;
}
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.card-header {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}
.card-title {
  margin-right: 8px;
}
.card-btn {
  cursor: pointer;
}
.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.marginBottom {
  margin-bottom: 12px;
}
.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}
.aType {
  color: #659fe5;
  cursor: pointer;
}
.tooltipClas {
  width: 250px;
}
.basic-info {
  .warning {
    color: #e6a23c;
  }
  .success {
    color: #67c23a;
  }
  .danger {
    color: #f56c6c;
  }
  .info {
    color: #409eff;
  }
}
.defaultTips {
  display: inline-block;
  margin: 0 8px;
  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}
</style>
